body, html {
  height: 100%;
}

body, form, table, td {
  margin: 0;
  padding: 0;
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, main, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

body {
  font-family: Arial, Tahoma, sans-serif;
  line-height: 1;
  background-color: #fff;
  position: relative;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

blockquote, q {
  quotes: none;
}

blockquote {
  &:after, &:before {
    content: "";
    content: none;
  }
}

q {
  &:after, &:before {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-width: 0;
}

html {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

input, textarea {
  color: #333;
  outline: 0;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
}

button {
  cursor: pointer;
  -webkit-appearance: none;
  outline: 0;
}

input {
  &[type=button], &[type=submit] {
    cursor: pointer;
    -webkit-appearance: none;
    outline: 0;
  }
}

a {
  color: #000100;
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  text-decoration: none;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
}

*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}