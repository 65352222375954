// local fonts example
// @include font-face('Circe', 'Circe-Bold', '../fonts/', 'Circe-Bold', 700, normal);

$font-family-primary: 'Montserrat', sans-serif;
$font-family-secondary: 'Montserrat', sans-serif;
$font-weight-primary: 400;

body {
  font-family: $font-family-primary;
  font-weight: $font-weight-primary;
  color: $clGrey;
}




@include respond-to('mobile') {}

@include respond-to('tablet') {}