body {
    background: rgba(112, 178, 206, 0.1);
    min-width: 360px;
}
.container {
    max-width: 1024px;
    padding: 0 32px;
    margin: 0 auto;
    @include respond-to("tablet-wide") {
        padding: 0 16px;
    }
}
.offer {
    &__container {
        display: flex;
        padding-right: 0;
        flex-wrap: wrap;
        @include respond-to("tablet-wide") {
            padding-right: 16px;
        }
    }
}
.info {
    font-size: 16px;
    line-height: 24px;
    width: 50%;
    min-height: calc(100vh - 50px);
    display: flex;
    align-items: center;
    position: relative;
    padding: 32px 0;
    @include respond-to("tablet-wide") {
        width: 100%;
        order: 2;
        min-height: auto;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        min-width: 100%;
        background: #fff;
        z-index: -1;
        @include respond-to("tablet-wide") {
            right: -16px;
        }
    }
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding-right: 24px;
        width: 100%;
        @include respond-to("tablet-wide") {
            padding-right: 0;
        }
    }
    &__logo {
        img {
            display: block;
        }
    }
    b {
        color: $clBlack;
    }
    span, a {
        color: $clLightBlue;
        font-weight: 700;
    }
    &__title, &__text {
        text-align: center;
        font-weight: 400;
    }
    &__list {
        padding: 0 16px;
        li {
            list-style: none;
            padding-left: 38px;
            position: relative;
            &:before {
                content: "";
                display: block;
                position: absolute;
                height: 24px;
                width: 24px;
                top: 2px;
                left: 2px;
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.9304 2.42332C10.5122 1.7914 8.92771 1.63486 7.41326 1.97703C5.89881 2.3192 4.53553 3.14175 3.52676 4.32201C2.51799 5.50228 1.91777 6.97701 1.81561 8.52627C1.71345 10.0755 2.11483 11.6163 2.95989 12.9188C3.80495 14.2213 5.04841 15.2157 6.50482 15.7538C7.96123 16.2919 9.55255 16.3447 11.0415 15.9045C12.5304 15.4643 13.8371 14.5546 14.7667 13.311C15.6964 12.0675 16.1991 10.5567 16.2 9.00412V8.25943C16.2 7.76238 16.6029 7.35943 17.1 7.35943C17.5971 7.35943 18 7.76238 18 8.25943V9.00463C17.9989 10.9454 17.3704 12.8344 16.2084 14.3888C15.0463 15.9432 13.413 17.0804 11.5518 17.6307C9.69068 18.1809 7.70153 18.1149 5.88102 17.4423C4.06051 16.7697 2.50619 15.5266 1.44986 13.8985C0.39354 12.2704 -0.108188 10.3444 0.0195091 8.40783C0.147206 6.47126 0.897486 4.62784 2.15845 3.15252C3.41942 1.67719 5.12351 0.648995 7.01657 0.221283C8.90963 -0.20643 10.8902 -0.0107452 12.663 0.779152C13.117 0.981453 13.3211 1.51351 13.1188 1.96754C12.9165 2.42156 12.3844 2.62562 11.9304 2.42332ZM17.7361 1.88792C18.0877 2.23922 18.088 2.80907 17.7367 3.16071L9.63672 11.2688C9.46796 11.4377 9.239 11.5327 9.00023 11.5327C8.76145 11.5328 8.53244 11.438 8.36361 11.2691L5.93361 8.83913C5.58214 8.48766 5.58214 7.91781 5.93361 7.56634C6.28508 7.21487 6.85493 7.21487 7.2064 7.56634L8.99968 9.35962L16.4633 1.88856C16.8146 1.53691 17.3844 1.53663 17.7361 1.88792Z' fill='%2370B2CE'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 18px;
                background-position: center center;
            }
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
}
.preview {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img,svg {
        max-width: 100%;
        @include respond-to("tablet-wide") {
            height: 280px;
        }
    }
    @include respond-to("tablet-wide") {
        width: 100%;
        order: 1;
    }
    @include respond-to("tablet-wide") {
        padding: 32px 0;
    }
    @include respond-to("mobile") {
        height: 360px;
    }
}
.footer {
    padding: 12px 0;
    background: $clBgBlack;
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        @include respond-to("tablet-wide") {
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
    }
    p {
        color: $clWhite;
        font-weight: 400;
        font-size: 12px;
        line-height: 26px;
    }
}

.skeleton {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    &__row {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        padding: 7px 8px 5px;
    }
    &__list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0 28px 0 16px;
        @include respond-to("tablet-wide") {
            padding-right: 22px;
        }
        &--item {
            display: flex;
            flex-direction: column;
            gap: 12px;
            position: relative;
            padding: 7px 0 5px 36px;
        }
    }
    &__check {
        height: 24px;
        width: 24px;
        background: #EEF4F8;
        border-radius: 24px;
        position: absolute !important;
        top: 4px;
        left: 0;
    }
    &__item {
        background: #EEF4F8;
        border-radius: 8px;
        max-width: 100%;
        height: 12px;
        &--mob {
            display: none;
            @include respond-to("tablet-wide") {
                display: block;
            }
        }
    }
    &__item, &__check {
        position: relative;
        overflow: hidden;
        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                            90deg,
                            rgba(#fff, 0) 0,
                            rgba(#fff, 0.2) 20%,
                            rgba(#fff, 0.5) 60%,
                            rgba(#fff, 0)
            );
            animation: shimmer 2s infinite;
            content: '';
        }

        @keyframes shimmer {
            100% {
                transform: translateX(100%);
            }
        }
    }
    .w100 {
        width: 100%;
    }
    .h1 {
        width: 257px;
    }
    .h2 {
        width: 313px;
        @include respond-to("tablet-wide") {
            width: 155px;
        }
    }
    .l1 {
        width: 204px;
        @include respond-to("tablet-wide") {
            width: 126px;
        }
    }
    .t1 {
        width: 405px;
        @include respond-to("tablet-wide") {
            width: 155px;
        }
    }
    .t2 {
        width: 411px;
    }
    .t3 {
        width: 369px;
        @include respond-to("tablet-wide") {
            width: 155px;
        }
    }
    .t4 {
        width: 236px;
        @include respond-to("tablet-wide") {
            width: 221px;
        }
    }
}
